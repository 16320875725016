import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';

import { repositoryName } from './utils';

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
const routes: prismic.ClientConfig['routes'] = [
  {
    type: 'default_landing',
    path: '/',
  },
  {
    type: 'how_invest_works',
    path: '/how-it-works',
  },
  {
    type: 'get_funded',
    path: '/get-funded',
  },
  {
    type: 'content',
    path: '/guides/:parent?/:uid',
    resolvers: {
      parent: 'parent_guide',
    },
  },
  {
    type: 'root',
    path: '/:uid',
  },
  {
    type: 'about_us',
    path: '/about-us/:uid',
  },
  {
    type: 'partners',
    path: '/partners/:uid',
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
const createClient = (clientConfig: prismic.ClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    accessToken: process.env.PRISMIC_ACCESS_TOKEN,
    routes,
    fetchOptions:
      process.env.NODE_ENV === 'production'
        ? { next: { tags: ['prismic'] }, cache: 'force-cache' }
        : { next: { revalidate: 5 } },
    ...clientConfig,
  });

  prismicNext.enableAutoPreviews({
    client,
  });

  return client;
};

export default createClient;
