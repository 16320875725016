// This is as hacky as it can be, i found it here :
// https://stackoverflow.com/questions/50428910/get-text-content-from-node-in-react
// This is not perfect
// This works
// I just edited the code to retrieve alt property if it's an img

const extractText = (node: React.ReactNode): string => {
  if (['string', 'number'].includes(typeof node)) {
    return node?.toString() ?? '';
  }
  if (node instanceof Array) {
    return node.map(extractText).join('');
  }
  if (typeof node === 'object' && node) {
    // @ts-expect-error node props
    if (node.props.alt) {
      // @ts-expect-error node props
      return node.props.alt;
    }
    // @ts-expect-error node props
    return extractText(node.props.children);
  }
  return '';
};

export default extractText;
